import * as React from "react";
import { graphql } from "gatsby";

import { GrayPhrase, PageHeading, Paragraph } from "@components/typography";
import { PageLayout, SEO } from "@components/page-layout";
import { GatsbyLink } from "@components/gatsby-link";
import { Box } from "@components/box";
import { FaqSearch } from "@components/faq-search";
import arrowRight from "@images/ic-arrow-long-right-gray.svg";
import { FaqList } from "@components/faq";

const parseFaqQuestionsList = (
  nodes: {
    slug: string;
    question: string;
    category: { name: string; slug: string };
  }[]
) =>
  nodes.map((node) => ({
    ...node,
    slug: `/faq/${node.category.slug}/${node.slug}`,
  }));

const FaqListTemplate = (props: any) => {
  const { allContentfulFaqQuestion } = props.data;

  return (
    <PageLayout>
      <SEO title={props.pageContext.metaTitle} />
      <Box className="text-center max-w-[660px] mx-auto mb-[66px] mt-[8.25rem] md:mt-[4rem] sm:px-[22px]">
        <PageHeading className="!w-full mb-[22px]">FAQ</PageHeading>
        <Paragraph>
          <GrayPhrase>
            Curabitur quam erat, sollicitudin et congue quis, elementum eu
            sapien. Pellentesque ut massa nec nunc sagittis condimentum eu at
            arcu.
          </GrayPhrase>
        </Paragraph>
      </Box>
      <Box
        display="flex"
        className="mx-auto max-w-[1320px] flex-col justify-between lg:px-[22px] md:flex-wrap md:justify-evenly"
      >
        <FaqSearch />
        <Box display="flex" className="text-[17px] mb-[66px]">
          <GatsbyLink to="/faq">FAQ</GatsbyLink>
          <img src={arrowRight} className="mx-[11px]" />
          <Paragraph>{props.pageContext.name}</Paragraph>
        </Box>
        <Box display="flex" className="w-full">
          {allContentfulFaqQuestion?.nodes ? (
            <FaqList
              categoryName={props.pageContext.name}
              categorySlug={props.pageContext.slug}
              list={parseFaqQuestionsList(allContentfulFaqQuestion.nodes)}
            />
          ) : null}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default FaqListTemplate;

export const pageQuery = graphql`
  query FAQQuestionByName($name: String!) {
    allContentfulFaqQuestion(filter: { category: { name: { eq: $name } } }) {
      nodes {
        slug
        question
        category {
          name
          slug
        }
      }
    }
  }
`;
